.product-wrap {
    position: relative;
    transition: all 0.3s ease;
  }
  .product-wrap:hover .product-transition .group-action {
    opacity: 1;
    visibility: visible;
  }
  
  .product-transition {
    position: relative;
    cursor: pointer;
  }
  
  .product-img {
    overflow: hidden;
    border-radius: 15px;
  }
  
  .product-transition .group-action {
    position: absolute;
    transition: all ease-in-out 0.3s;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    bottom: 10px;
    right: 10px;
  }
  
  .product-transition .group-action .shop-action {
    display: flex;
  }
  
  .product-transition .group-action .shop-action.vertical {
    flex-direction: column;
    align-items: flex-end;
  }
  
  .product-transition .group-action .shop-action .btn-opt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 0;
    letter-spacing: 0;
    border-radius: 50%;
    border: none;
    transition: all ease 0.3s;
    color: #000;
    background-color: #fff;
    font-size: 14px;
  }
  
  .product-transition .group-action .shop-action .btn-opt:hover {
    background-color: #2BC239;
    color: #fff;
    fill:#fff;
  }
  .product-transition .group-action .shop-action .btn-opt:hover svg {
    color: #fff;
  }
  .product-transition .group-action .shop-action.vertical > *:not(:last-child) {
    margin-bottom: 4px;
  }